import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0,9,10,11,2,3];

export const dictionary = {
		"/": [~12],
		"/.well-known/change-password": [13],
		"/auth/duplicate-email": [23],
		"/cancellation": [24,[8]],
		"/dev/embed": [25,[9]],
		"/dev/flamingo": [26,[9]],
		"/invite": [27],
		"/invite/[token]": [28],
		"/sign-up": [29,[10]],
		"/sign-up/[step]": [30,[10]],
		"/upgrade-success": [31],
		"/workspace/[workspaceId]": [~32,[11]],
		"/workspace/[workspaceId]/billing": [~33,[11]],
		"/workspace/[workspaceId]/upgrade": [34,[11]],
		"/workspace/[workspaceId]/users": [35,[11]],
		"/[main]/[idOrToken]": [~14,[2]],
		"/[main]/[idOrToken]/change-plan": [15,[2,3]],
		"/[main]/[idOrToken]/embed": [16,[2]],
		"/[main]/[idOrToken]/export": [~17,[2]],
		"/[main]/[idOrToken]/forecast": [18,[2,4]],
		"/[main]/[idOrToken]/functional-chart": [19,[2,5]],
		"/[main]/[idOrToken]/integration/[provider]": [20,[2]],
		"/[main]/[idOrToken]/orgchart": [21,[2,6]],
		"/[main]/[idOrToken]/positions": [22,[2,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';